import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import {useDispatch} from 'react-redux';
import {FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {rootReducer as mapquestReducer} from '../sites/mapquest/rootReducer';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['canvas']
};

export const getReducer = () => {
    return mapquestReducer;
};

export const store = configureStore({
    reducer: persistReducer(persistConfig, getReducer()),
    middleware: getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
    })
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
