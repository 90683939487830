// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, {FC, PropsWithChildren, useEffect, useState} from 'react';
import {Global, css} from '@emotion/core';
import {useLocation} from '@reach/router';
import SEO from '../components/SEO';
import {useLogging} from '../../common/hooks/useLogging';
import {useAppDispatch} from '../stores';
import {fetchLocation} from '../reducers/locationSlice';

const Layout: FC<PropsWithChildren<{}>> = ({children}) => {
    const {PageViewEvent} = useLogging();
    const {pathname} = useLocation();
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(fetchLocation()).finally(() => {
            PageViewEvent({page: pathname});
        });
    }, [PageViewEvent, dispatch, pathname]);

    return (
        <div
            css={css`
                min-height: 100vh;
                display: flex;
                flex-direction: column;
                margin: 0;
                padding: 0;
            `}
        >
            <Global
                styles={() => ({
                    body: {margin: 0, padding: 0}
                })}
            />
            <SEO noIndex />
            {children}
        </div>
    );
};

export default Layout;
