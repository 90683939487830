import React, {FC, PropsWithChildren, Suspense, useEffect, useState} from 'react';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {ExperimentContextProvider} from '../src/common/experiments/experimentContext';
import {getReducer, persistor, store} from '../src/common/stores';
import Layout from '../src/common/templates/Layout';

const WrapWithProvider: FC<PropsWithChildren<{}>> = ({children}) => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ExperimentContextProvider>{children}</ExperimentContextProvider>
            </PersistGate>
        </Provider>
    );
};

export default WrapWithProvider;
