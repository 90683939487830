import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {localStore, RemoteStore} from '@s1/extensions';
import * as yup from 'yup';
import {GAClientId} from '../hooks/useLogging';
import {getExtensionName} from '../ extensionMap';

const initialState = {
    valid: false,
    errMsg: '',
    installDate: '',
    extName: '',
    extversion: '',
    browser: '',
    channel: '',
    campaign: '',
    adgroup: '',
    update: false
};

const TypeTagSchema = yup
    .object({
        installDate: yup.string().default('').defined(),
        extVersion: yup.string().default('').defined(),
        browser: yup.string().default('').defined(),
        channel: yup.string().defined(),
        campaign: yup.string().default('').defined(),
        adgroup: yup.string().default('').defined(),
        update: yup.boolean().notRequired().default(false)
    })
    .defined();

type TypeTag = yup.InferType<typeof TypeTagSchema>;

const isValidDate = (date: unknown) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    // eslint-disable-next-line no-restricted-globals
    return date instanceof Date && !isNaN(date);
};

const checkIfUpdate = (installDate?: string) => {
    if (installDate === undefined) {
        return false;
    }
    const date = new Date(installDate);

    // month is zero indexed.
    const update = new Date(2020, 9, 30);

    if (!isValidDate(date)) {
        return false;
    }
    return update > date;
};

const localStorageFallback = () =>
    new Promise((resolve) => {
        const store = localStore();
        const data = store.get();
        return resolve({...data, update: checkIfUpdate(data.installDate)});
    });

const grabDataAndValidate = async (dataFetch: () => Promise<unknown>) => {
    let dataFetchResult: Partial<typeof initialState>;
    try {
        const data = await dataFetch();
        dataFetchResult = {
            valid: true,
            errMsg: '',
            ...(await TypeTagSchema.validate(data))
        };
    } catch (e) {
        dataFetchResult = {valid: false, errMsg: e.toString()};
    }
    return dataFetchResult;
};

export const fetchRemoteConfig = createAsyncThunk('remoteConfig', async () => {
    const clientId = await GAClientId;
    const extensionName = getExtensionName(window.location.pathname);
    if (extensionName === '') {
        return {error: true};
    }
    const remoteStore = RemoteStore(clientId, extensionName);
    const remoteResult = await grabDataAndValidate(() => remoteStore.get());

    // Return remote state if valid
    if (remoteResult.valid) {
        return remoteResult;
    }

    // return fallback, if this also returns an error we'll store it.
    const localStorageResult = await grabDataAndValidate(localStorageFallback);
    return localStorageResult;
});

export const remoteConfigSlice = createSlice({
    name: 'remoteConfig',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchRemoteConfig.fulfilled, (state, action) => {
            return {...state, ...action.payload};
        });
    }
});
