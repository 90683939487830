import React, {FC} from 'react';
import {Helmet} from 'react-helmet';
import {useStaticQuery, graphql} from 'gatsby';

type MetaTag = {
    name: string;
    content: string;
};

type SEOProps = {
    description?: string;
    lang?: string;
    meta?: MetaTag[];
    title?: string;
    noIndex?: boolean;
};

const SEO: FC<SEOProps> = ({description, lang, meta = [], title, noIndex = false}) => {
    const {site} = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                    }
                }
            }
        `
    );

    const metaDescription = description || site.siteMetadata.description;

    const metaTags = [
        {
            name: `description`,
            content: metaDescription
        },
        {
            property: `og:title`,
            content: title
        },
        {
            property: `og:description`,
            content: metaDescription
        },
        {
            property: `og:type`,
            content: `website`
        },
        {
            name: `twitter:card`,
            content: `summary`
        },
        {
            name: `twitter:creator`,
            content: site.siteMetadata.author
        },
        {
            name: `twitter:title`,
            content: title
        },
        {
            name: `twitter:description`,
            content: metaDescription
        }
    ].concat(meta);

    if (noIndex) {
        metaTags.push({
            name: 'robots',
            content: 'noindex'
        });
    }

    return (
        <Helmet
            htmlAttributes={{
                lang
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={metaTags}
            defer={false}
        />
    );
};

export default SEO;
