// Logs when the client route changes
import React from 'react';
import {GatsbyBrowser} from 'gatsby';
import {ExperimentContextProvider} from '../src/common/experiments/experimentContext';
import Layout from '../src/common/templates/Layout';
import WrapWithProvider from './wrapWithProvider';

// Layout needs `useLocation` to function
export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({element}) => <Layout>{element}</Layout>;

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({element}) => (
    <WrapWithProvider>{element}</WrapWithProvider>
);
