import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState = {
    pageLoadCount: 0
};

const incrementPageLoadSlice = createSlice({
    name: 'incrementPageCount',
    initialState,
    reducers: {
        incrementPageLoad: (state, action: PayloadAction<number>) => {
            return {...state, pageLoadCount: state.pageLoadCount + action.payload};
        }
    }
});

export default incrementPageLoadSlice;
