module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[],"preset":{"colors":{"primary":"#397ec2","secondary":"#d8ecfe","background":"#fff","text":"#6a6a6b"},"fonts":{"body":"Arial","heading":"Comfortaa","fontSizes":[12]},"fontWeights":{"body":400,"bold":700},"text":{"default":{"color":"text","fontFamily":"Arial"},"heading":{"fontFamily":"Comfortaa","fontSize":"48px","fontWeight":"500","color":"text","margin":"0 auto 20px","textAlign":"center","textTransform":"uppercase"},"paragraph":{"color":"text","fontFamily":"body","fontSize":"19px","fontWeight":500,"textAlign":"center"},"description":{"color":"primary","fontFamily":"body","fontSize":"14px","fontWeight":500,"margin":"4% auto 6%","textAlign":"center"}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Comfortaa","Arial"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"projectRoot":"/Users/albert.park/github/s1/OpenMail/frontend/packages/browser-extension-marketing-pages","configDir":".gatsby"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"/Users/albert.park/github/s1/OpenMail/frontend/packages/browser-extension-marketing-pages/src/sites/openmail/assets/images/favicon.svg","cache_busting_mode":"none","name":"MapQuest","short_name":"MapQuest","start_url":"/","background_color":"#000","theme_color":"#000","display":"standalone","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.openmail.com"},
    }]
