/* eslint-disable @typescript-eslint/camelcase */
import {createAsyncThunk, createSelector, createSlice} from '@reduxjs/toolkit';
import * as yup from 'yup';
import {RootState} from '../../sites/mapquest/rootReducer';

const URL = 'https://vs-api.s1search.co/geolocation';

const locationSchema = yup
    .object({
        city: yup.string().default('').defined(),
        country: yup.string().default('').defined(),
        geoname: yup.string().default('').defined(),
        isInCalifornia: yup.boolean().default(false).defined(),
        isInEuropeanUnion: yup.boolean().default(false).defined(),
        isoCode: yup.string().default('').defined(),
        latitude: yup.number().nullable().default(null).defined(),
        longitude: yup.number().nullable().default(null).defined(),
        postalCode: yup.string().default('').defined(),
        state: yup.string().default('').defined(),
        stateIsoCode: yup.string().default('').defined()
    })
    .camelCase()
    .defined();

type Location = yup.InferType<typeof locationSchema>;
type LocationState = Location & {
    error: boolean;
    fulfilled: boolean;
    errMsg: string;
};

const initialState: LocationState = {
    error: false,
    fulfilled: false,
    errMsg: '',
    city: '',
    country: '',
    geoname: '',
    isInCalifornia: false,
    isInEuropeanUnion: false,
    isoCode: '',
    latitude: null,
    longitude: null,
    postalCode: '',
    state: '',
    stateIsoCode: ''
};

type RejectionPayload = Pick<LocationState, 'errMsg'>;

export const fetchLocation = createAsyncThunk<Partial<Location>, void, {rejectValue: RejectionPayload}>(
    'location',
    async (_, thunkAPI) => {
        let data: Partial<Location> = {};

        try {
            const response = await fetch(URL);
            if (response.status > 200) {
                return thunkAPI.rejectWithValue({errMsg: `status: ${response.status}`});
            }
            const payload = await locationSchema.validate(await response.json());
            data = payload;
        } catch (e) {
            return thunkAPI.rejectWithValue({errMsg: e.toString()});
        }
        return data;
    }
);

export const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchLocation.fulfilled, (state, action) => {
            return {...state, ...action.payload, fulfilled: true, error: false};
        });
        builder.addCase(fetchLocation.rejected, (state, action) => {
            return {...state, ...action.payload, fulfilled: false, error: true};
        });
    }
});

export const locationSelector = (state: RootState) => state.location;

export const coordinateSelector = createSelector(locationSelector, ({latitude, longitude}) => ({latitude, longitude}));
export type Coords = ReturnType<typeof coordinateSelector>;
