import {useLocation} from '@reach/router';
import {URLMarketingParams, localStore} from '@s1/extensions';

const store = localStore();

type LocationState = {
    channel?: string;
};

export const useChannel = () => {
    const location = useLocation<LocationState>();
    const stateChannel = location.state?.channel;
    const {channel: URLChannel} = URLMarketingParams();
    const {channel: storageChannel} = store.get();
    return `${stateChannel || URLChannel || storageChannel || '1'}`;
};
