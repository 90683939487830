import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {PixelData} from '../../../../../common/imaging';

const initialState = {
    showUpdateModal: false,
    newPhotoEveryTime: false,
    showCanvasOverlay: false,
    useAvgColor: false,
    logoColor: {r: 0, g: 0, b: 0, a: 0},
    showMissingFavicons: false
};

const debugSlice = createSlice({
    name: 'debug',
    initialState,
    reducers: {
        showUpdateModal: (state, action: PayloadAction<boolean>) => {
            return {...state, showUpdateModal: action.payload};
        },
        newPhotoEveryTime: (state, action: PayloadAction<boolean>) => {
            return {...state, newPhotoEveryTime: action.payload};
        },
        showCanvasOverlay: (state, action: PayloadAction<boolean>) => {
            return {...state, showCanvasOverlay: action.payload};
        },
        useAvgColor: (state, action: PayloadAction<boolean>) => {
            return {...state, useAvgColor: action.payload};
        },
        setLogoColor: (state, action: PayloadAction<PixelData>) => {
            return {...state, logoColor: action.payload};
        },
        showMissingFavicons: (state, action: PayloadAction<boolean>) => {
            return {...state, showMissingFavicons: action.payload};
        }
    }
});

export default debugSlice;
