import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState = {
    isCanvasReady: false,
    canvasRender: '',
    backgroundColor: '',
    backgroundState: '',
    usePhotoGallery: false
};
const canvasSlice = createSlice({
    name: 'canvas',
    initialState,
    reducers: {
        setCanvasReady: (state, action: PayloadAction<boolean>) => {
            return {...state, isCanvasReady: action.payload};
        },
        setCanvasRender: (state, action: PayloadAction<string>) => {
            return {...state, canvasRender: action.payload};
        },
        setBackgroundState: (state, action: PayloadAction<string>) => {
            return {...state, backgroundState: action.payload};
        }
    }
});
export default canvasSlice;
