import {combineReducers} from '@reduxjs/toolkit';
import {locationSlice} from '../../common/reducers/locationSlice';
import {remoteConfigSlice} from '../../common/reducers/remoteConfig';
import debugSlice from './components/new-tab/SideBar/debugSlice';
import canvasSlice from './hooks/background-inspector/canvasSlice';
import incrementPageLoadSlice from '../../common/reducers/appSlice';

export const rootReducer = combineReducers({
    remoteConfig: remoteConfigSlice.reducer,
    debug: debugSlice.reducer,
    location: locationSlice.reducer,
    canvas: canvasSlice.reducer,
    pageLoad: incrementPageLoadSlice.reducer
});

export type RootState = ReturnType<typeof rootReducer>;
