import {Logging, SOURCE} from '@s1/extensions';
import {extensions} from '@s1/dpl';
import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useExperiment} from '../experiments/experimentContext';
import {gtmId, gaId as gaMeasurementId} from '../constants';
import {getExtensionName} from '../ extensionMap';
import {useChannel} from '../hooks/useChannel';
import {locationSelector} from '../reducers/locationSlice';

const {
    ClickEvent,
    PageViewEvent,
    NewTabEvent,
    InstallEvent,
    UninstallEvent,
    SearchEvent,
    ExtensionsErrorEvent,
    InfoEvent,
    clientId
} = Logging(SOURCE.WEB, {
    gtmId,
    gaMeasurementId
});

export const GAClientId = clientId;

export const useLogging = () => {
    const {experimentId} = useExperiment();
    const extName = typeof window === 'undefined' ? '' : getExtensionName(window.location.pathname);
    const channel = useChannel();
    const {
        city,
        country,
        geoname,
        isInCalifornia,
        isInEuropeanUnion,
        isoCode,
        latitude,
        longitude,
        postalCode,
        state,
        stateIsoCode
    } = useSelector(locationSelector);

    const getOverrideParams = useCallback(() => {
        const override = {
            experimentId,
            channel,
            extName,
            isoCode,
            city,
            country,
            geoname,
            isInCalifornia,
            isInEuropeanUnion,
            postalCode,
            state,
            stateIsoCode
        };
        if (latitude !== null && longitude !== null) {
            return {...override, latitude, longitude};
        }
        return override;
    }, [
        channel,
        city,
        country,
        experimentId,
        extName,
        geoname,
        isInCalifornia,
        isInEuropeanUnion,
        isoCode,
        latitude,
        longitude,
        postalCode,
        state,
        stateIsoCode
    ]);

    return {
        ExtensionsErrorEvent: useCallback(
            (params: extensions.ExtensionsErrorParams) => {
                return ExtensionsErrorEvent(params, getOverrideParams());
            },
            [getOverrideParams]
        ),
        NewTabEvent: useCallback(
            (logGA = true) => {
                return NewTabEvent(getOverrideParams(), logGA);
            },
            [getOverrideParams]
        ),
        SearchEvent: useCallback(
            (params: extensions.SearchParams, logGA = true) => {
                return SearchEvent(params, getOverrideParams(), logGA);
            },
            [getOverrideParams]
        ),
        PageViewEvent: useCallback(
            (params: extensions.PageViewParams, logGA = true) => {
                return PageViewEvent(params, getOverrideParams(), logGA);
            },
            [getOverrideParams]
        ),
        InstallEvent: useCallback(
            (logGA = true) => {
                return InstallEvent(getOverrideParams(), logGA);
            },
            [getOverrideParams]
        ),
        UninstallEvent: useCallback(
            (logGA = true) => {
                return UninstallEvent(getOverrideParams(), logGA);
            },
            [getOverrideParams]
        ),
        ClickEvent: useCallback(
            (params: extensions.ClickParams, override: Partial<extensions.BaseExtensionParams> = {}, logGA = true) => {
                return ClickEvent(params, {...getOverrideParams(), ...override}, logGA);
            },
            [getOverrideParams]
        ),
        InfoEvent: useCallback(
            (params: Pick<extensions.InfoParams, 'message' | 'type'>) => {
                return InfoEvent(params, {extName});
            },
            [extName]
        )
    };
};
